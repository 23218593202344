import React from "react";
import * as S from "./ReleaseImage.styles";

type Props = {
  src: string;
  zIndex: number;
};

const ReleaseImage = ({ src, zIndex }: Props) => {
  const t = React.useRef(Math.floor(Math.random() * (12 - -12) + -12));
  return <S.ReleaseImage src={src} tilt={t.current} zIndex={zIndex} />;
};

export default ReleaseImage;
