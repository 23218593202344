import styled from "styled-components";

export const ReleaseImage = styled.img<{
  tilt: number;
  zIndex: number;
}>`
  transform: rotate(${(props) => props.tilt}deg);
  width: 25vw;
  z-index: ${(props) => props.zIndex};

  @media only screen and (max-width: 767px) {
    width: 80vw;
  }
`;
