import React from "react";
import * as S from "./Pile.styles";
import { ContentQuery } from "../../../graphql-types";
import PileImage from "../PileImage/PileImage";

type Props = {
  data: ContentQuery["releases"]["nodes"];
};

const Pile = ({ data }: Props) => {
  const imageData = data.map((release) => release.images).flat();
  return (
    <S.Pile>
      {imageData.map((img, i) => (
        <PileImage src={img?.url ?? ""} key={img?.title} zIndex={-i} />
      ))}
    </S.Pile>
  );
};

export default Pile;
