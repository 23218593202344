import React from "react";
import * as S from "./PileImage.styles";

type Props = {
  src: string;
  zIndex: number;
};

const isBrowser = typeof window !== "undefined";

const PileImage = ({ src, zIndex }: Props) => {
  let w: number;
  if (isBrowser) w = window.screen.width;

  const genX = () =>
    w < 768
      ? Math.floor(Math.random() * (40 - -10) + -10)
      : Math.floor(Math.random() * (80 - 5) + 5);

  const genY = () =>
    w < 768
      ? Math.floor(Math.random() * (80 - -5) + -5)
      : Math.floor(Math.random() * (50 - 10) + 10);

  const x = React.useRef(genX());
  const y = React.useRef(genY());
  const t = React.useRef(Math.floor(Math.random() * (12 - -12) + -12));

  return (
    <S.PileImage
      src={src}
      x={x.current}
      y={y.current}
      tilt={t.current}
      zIndex={zIndex}
    />
  );
};

export default PileImage;
