import styled from "styled-components";

export const Contact = styled.footer``;

export const IndexPage = styled.main<{ opacity: number }>`
  opacity: ${(props) => props.opacity};
  position: relative;
  transition: opacity 1s linear;
`;

export const Menu = styled.nav``;

export const PileWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  transition: opacity 0.5s linear;
  width: 100vw;
`;

export const Releases = styled.div``;

export const ReleasesWrapper = styled.div`
  transition: opacity 0.5s linear;
`;

export const Cover = styled.div`
  width: 100vh;
  height: 100vh;
`;
