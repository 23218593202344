import * as React from "react";
import * as S from "../styles/index.styles";
import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";
import { ContentQuery } from "../../graphql-types";
import Releases from "../components/Releases/Releases";
import Pile from "../components/Pile/Pile";

interface Props {
  data: ContentQuery;
}

const IndexPage = ({ data }: Props) => {
  const [opacity, setOpacity] = React.useState(1);
  const [display, setDisplay] = React.useState("pile");
  const [cover, setCover] = React.useState(true);

  const showReleases = () => {
    setOpacity(0);
    setTimeout(() => {
      setDisplay("releases");
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }, 1100);
  };

  setTimeout(() => {
    setCover(false);
  }, 100);

  return (
    <S.IndexPage opacity={opacity}>
      {cover ? (
        <S.Cover />
      ) : display === "pile" ? (
        <S.PileWrapper onClick={showReleases}>
          <Pile data={data.releases.nodes} />
        </S.PileWrapper>
      ) : (
        <S.ReleasesWrapper>
          <Releases data={data.releases.nodes} />
        </S.ReleasesWrapper>
      )}
    </S.IndexPage>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Relaxin Records</title>
    <meta name="url" content="www.relaxinrecords.com" />
    <meta name="title" content="Relaxin Records" />
    <meta
      name="description"
      content="Relaxin Records is a label by Lolina, est. 2022"
    />
    <meta
      name="keywords"
      content="lolina, relaxin, records, music, art, inga copeland, hype williams"
    />
    <meta name="author" content="Lolina" />
  </>
);

export const query = graphql`
  query Content {
    releases: allContentfulRelease(sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        images {
          title
          url
        }
        links
        createdAt(formatString: "DD MMMM YYYY")
      }
    }
  }
`;
