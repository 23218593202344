import styled from "styled-components";

export const Releases = styled.div``;

export const Email = styled.p`
  font-family: "Fahkwang", sans-serif;
  padding: 5px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;

  @media only screen and (max-width: 1023px) {
    padding: 1rem;
    position: absolute;
    margin-top: 2.5rem;
    right: auto;
    text-align: center;
    top: auto;
    width: 100vw;
  }

  @media only screen and (max-width: 320px) {
    font-size: 0.8rem;
  }
`;
