import styled from "styled-components";

export const PileImage = styled.img<{
  tilt: number;
  x: number;
  y: number;
  zIndex: number;
}>`
  left: ${(props) => props.x}vw;
  position: absolute;
  top: ${(props) => props.y}vh;
  transform: rotate(${(props) => props.tilt}deg);
  width: 25vw;
  z-index: ${(props) => props.zIndex};

  @media only screen and (max-width: 767px) {
    width: 55vw;
  }
`;
