import styled from "styled-components";

export const Link = styled.a``;

export const Release = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  justify-content: center;
  min-height: 100vh;
  padding: 5rem;
  width: 100vw;

  @media only screen and (max-width: 1023px) {
    min-height: fit-content;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 3rem;
    padding: 3rem;
    min-height: 100vh;
    max-height: fit-content;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export const Title = styled.p`
  font-family: "Fahkwang", sans-serif;
  left: 0;
  position: absolute;
  transform: rotate(270deg);

  @media only screen and (max-width: 767px) {
    position: relative;
    left: auto;
    transform: none;
  }
`;
