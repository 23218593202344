import React from "react";
import * as S from "./Releases.styles";
import Release from "../Release/Release";
import { ContentQuery } from "../../../graphql-types";

type Props = {
  data: ContentQuery["releases"]["nodes"];
};

const Releases = ({ data }: Props) => {
  return (
    <S.Releases>
      {data.map((release, i) => (
        <Release data={release} key={i} />
      ))}
      <S.Email>RELAXINRECORDS@PROTONMAIL.COM</S.Email>
    </S.Releases>
  );
};
export default Releases;
