import React from "react";
import slugify from "@sindresorhus/slugify";
import * as S from "./Release.styles";
import ReleaseImage from "../ReleaseImage/ReleaseImage";
import { ContentQuery } from "../../../graphql-types";

type Props = {
  data: ContentQuery["releases"]["nodes"][0];
};

const Release = ({ data }: Props) => {
  const id = data?.title && slugify(data.title);
  return (
    <S.Release id={`${id}`}>
      <S.Title>{data.title}</S.Title>
      {data?.images &&
        data.images.map((e, i) => {
          const linkUrl = data?.links && data.links[i];
          return (
            <S.Link
              key={i}
              href={linkUrl ?? ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReleaseImage
                src={e?.url ?? ""}
                zIndex={i}
                key={`${data.title}-${i}`}
              />
            </S.Link>
          );
        })}
    </S.Release>
  );
};

export default Release;
